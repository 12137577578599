<template>
  <v-icon v-if="!eventData">help</v-icon>
  <v-icon v-else-if="eventData.severity === Severity.ERROR" color="error">error</v-icon>
  <v-icon v-else-if="eventData.severity === Severity.WARNING" color="warning">warning</v-icon>
  <v-icon v-else-if="eventData.severity === Severity.INFO" color="primary">info</v-icon>
  <v-icon v-else>help</v-icon>
</template>

<script>
import {Component, Prop} from "vue-property-decorator";
import {Vue} from "vue-property-decorator";
import Severity from "@/types/Severity";

@Component({
  computed: {
    Severity() {
      return Severity
    }
  }
})
export default class SeverityIcon extends Vue {
  @Prop() eventData;
}
</script>
