

























import { Component, Vue } from 'vue-property-decorator';
import {mapGetters, Store} from 'vuex';
import UserInfo from "@/types/UserInfo";
// import { readUserProfile } from '@/store/main/getters';
import AlarmListCard from './maintenance/AlarmListCard.vue';
import EventListCard from './maintenance/EventListCard.vue';
import OrganisationList from "@/views/main/maintenance/OrganisationList.vue";
// import StatusMap from './maintenance/StatusMap.vue';

@Component({
  computed: mapGetters({
    userInfo: 'api/userInfo'
  }),
  components: {
    OrganisationList,
    AlarmListCard,
    EventListCard,
    // StatusMap,
  },
})
export default class Dashboard extends Vue {
  userInfo!: UserInfo|null;

  get greetedUser() {
    if (this.userInfo) {
      return this.userInfo.firstName;
    }
  }
}
