var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Alerts")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.alarms || [],"loading":_vm.loading,"items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatApiTimeString(item.timestamp))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
                name: 'main-maintenance-alarm-view',
                params: { alarmId: item.id },
              }}},[_c('v-icon',[_vm._v("pageview")])],1)]}},{key:"item.severity",fn:function(ref){
              var item = ref.item;
return [_c('EventSeverityIcon',{attrs:{"event-data":item}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }