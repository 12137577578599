var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Organisations")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.organizations || [],"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('organization-status-icon',{attrs:{"organization-data":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
              name: 'main-maintenance-organisation-view',
              params: { organizationId: item.id },
            }}},[_c('v-icon',[_vm._v("pageview")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }