































import { Component, Vue } from 'vue-property-decorator';
import { OutputOrganizationDTO } from '@/api/api';
import OrganizationStatusIcon from "@/components/OrganizationStatusIcon.vue";
@Component({
  components: {OrganizationStatusIcon}
})
export default class OrganisationList extends Vue {
  public headers = [
    {
      text: 'Status',
      value: 'status',
      align: 'center'
    },
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
      value: 'actions',
    },
  ];
  public organizations: OutputOrganizationDTO[]|null = null;

  public async mounted() {
    this.organizations = await this.$store.dispatch('organizations/getAll');
  }

  get loading() {
    return this.organizations === null;
  }
}
