








































import {Component, Prop, Vue} from 'vue-property-decorator';
import { EventBus } from '@/main';
import {formatApiTimeString, updateObjectList} from '@/utils';
import {OutputRuleTriggerDTO} from "@/api";
import SeverityIcon from "@/components/EventSeverityIcon.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    EventSeverityIcon: SeverityIcon,
    Loading
  }
})
export default class AlarmListCard extends Vue {
  public headers = [
    {
      text: 'Severity',
      sortable: true,
      value: 'severity',
      align: 'center'
    },
    {
      text: 'Timestamp',
      sortable: true,
      value: 'timestamp',
      align: 'left',
    },
    {
      text: 'Message',
      sortable: true,
      value: 'message',
      align: 'center'
    },
    {
      text: 'Location',
      sortable: true,
      value: 'location_id',
      align: 'left',
    },
    {
      text: 'Actions',
      sortable: false,
      value: 'actions',
    },
  ];

  @Prop(Number) readonly locationId;
  @Prop(Number) readonly deviceId;
  @Prop(Number) readonly organizationId;
  @Prop(Number) readonly itemsPerPage;

  // public alarms: Alarm[] = [];
  public alarms: OutputRuleTriggerDTO[]|null = null;
  public category: string | undefined = undefined;
  public severity: number | undefined = undefined;

  // if true, filter on active alarms, if false do not filter on is_active flag.
  public filterIsActive = true;

  public async created() {
    EventBus.$on('alarm', (data) => {
      this.alarms = updateObjectList(this.alarms, data);
    });
  }

  public async mounted() {
    this.filterIsActive = true;
    await this.fetchData();
  }

  public async fetchData() {
    this.alarms = null;  // Empty the alarms list to trigger the loading animation

    const isActive = this.filterIsActive ? true : undefined;

    if (this.deviceId) {
      this.alarms = await this.$store.dispatch('ruleTriggers/getByDevice', { deviceId: this.deviceId })
    } else if (this.locationId) {
      this.alarms = await this.$store.dispatch('ruleTriggers/getByLocation', { locationId: this.locationId })
    } else if (this.organizationId) {
      this.alarms = await this.$store.dispatch('ruleTriggers/getByOrganization', { organizationId: this.organizationId })
    } else {
      this.alarms = await this.$store.dispatch('ruleTriggers/getAll')
    }
  }

  public formatApiTimeString = formatApiTimeString;

  get loading() {
    return this.alarms === null;
  }
}
