enum Severity {
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    EMERGENCY = 'EMERGENCY'
}

export default Severity;

export function getSeveritySelectItems() {
    return [
        {
            value: Severity.DEBUG,
            text: 'Debug'
        },
        {
            value: Severity.INFO,
            text: 'Info'
        },
        {
            value: Severity.WARNING,
            text: 'Warning'
        },
        {
            value: Severity.ERROR,
            text: 'Error'
        },
        {
            value: Severity.EMERGENCY,
            text: 'Emergency'
        }
    ]
}
