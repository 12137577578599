






































import {Component, Prop, Vue} from 'vue-property-decorator';
import { EventBus } from '@/main';
import {formatApiTimeString, updateObjectList} from '@/utils';
import SeverityIcon from "@/components/EventSeverityIcon.vue";

@Component({
  components: {EventSeverityIcon: SeverityIcon}
})
export default class EventListCard extends Vue {
  @Prop() locationId;
  @Prop() deviceId;
  @Prop() organizationId;
  @Prop() itemsPerPage;

  public headers = [
    {
      text: 'Type',
      sortable: false,
      value: 'eventIcon',
      align: 'center'
    },
    {
      text: 'Timestamp',
      sortable: true,
      value: 'timestamp',
      align: 'left',
    },
    {
      text: 'Details',
      sortable: false,
      value: 'message',
      align: 'left',
    },
    {
      text: 'Actions',
      sortable: false,
      value: 'actions',
    },
  ];
  public events: Event[]|null = null;

  public async created() {
    EventBus.$on('event', (data) => {
      console.log(data);
      this.events = updateObjectList(this.events, data);
    });
  }

  public async mounted() {
    this.updateEvents();
  }

  public async updateEvents() {
    if (this.deviceId) {
      this.events = await this.$store.dispatch('deviceEvents/getByDevice', { deviceId: this.deviceId })
    } else if (this.locationId) {
      this.events = await this.$store.dispatch('deviceEvents/getByLocation', { locationId: this.locationId })
    } else if (this.organizationId) {
      this.events = await this.$store.dispatch('deviceEvents/getByOrganization', { organizationId: this.organizationId })
    } else {
      this.events = await this.$store.dispatch('deviceEvents/getAll')
    }
  }

  public formatApiTimeString = formatApiTimeString;

  get loading() {
    return this.events === null;
  }
}
