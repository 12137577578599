var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Events")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.events || [],"loading":_vm.loading,"items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatApiTimeString(item.timestamp))+" ")]}},{key:"item.eventIcon",fn:function(ref){
var item = ref.item;
return [_c('EventSeverityIcon',{attrs:{"event-data":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
            name: 'main-maintenance-event-view',
            params: {
              organizationId: item.organization_id,
              locationId: item.location_id,
              deviceId: item.device_id,
              eventId: item.id,
            },
          }}},[_c('v-icon',[_vm._v("pageview")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }